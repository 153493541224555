import "./App.css";
import TextInput from "./Components/TextInput/TextInput";
import {Container, Row} from "react-bootstrap";
import {useState} from "react";
import vkImage from "./assets/images/png/vk.png"
import rutubeImage from "./assets/images/png/rutube.png"
import {availableSettings} from "./utils";

const VK = 'vk'
const RUTUBE = 'rutube'

function App() {
  const [linkType, setLinkType] = useState(VK)

  const setLink = (linkName) => setLinkType(linkName);

  return (
    <div className="app">
        <Container>
            <Row>
                <div className={"app-links"}>
                    <div className={`links-item ${linkType === VK && "active-link"}`} onClick={() => setLink(VK)}>
                        <img width={"100px"} src={vkImage} alt={linkType}/>
                    </div>
                    <div className={`links-item ${linkType === RUTUBE && "active-link"}`} onClick={() => setLink(RUTUBE)}>
                        <img width={"100px"} src={rutubeImage} alt={linkType}/>
                    </div>
                </div>
            </Row>

            <Row className="p-20">
                <TextInput link={linkType} settings={availableSettings[linkType]} />
            </Row>
        </Container>
    </div>
  );
}

export default App;
