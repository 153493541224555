import {Button, Form} from "react-bootstrap";
import React, {useState} from "react";
import {getId, makeDeepLinkFromUrl} from "../../utils";

function TextInput({settings}) {
	const [value, setValue] = useState("");
	const [show, setShow] = useState(false);


	const handleChange = (event) => setValue(event.target.value);

	const copy = () => {
		navigator.clipboard.writeText(makeDeepLinkFromUrl(value, settings))
		setShow(true)
		setTimeout(() => setShow(false), 3000)
	}

	return (
		<>
			<Form.Label htmlFor="deepLinkInput">{settings.name} ссылка</Form.Label>
			<Form.Control
				type="text"
				id="deepLinkInput"
				aria-describedby="deepLinkInputInfo"
				onChange={event => handleChange(event)}
				value={value}
			/>
			<Form.Text id="deepLinkInputInfo" muted>
				Примерный формат ссылки <span className="highlight" >{settings.example}</span>
			</Form.Text>
			<Form.Text id="deepLinkInputInfo" muted>
				Важно наличие в ссылке параметра <span className="highlight" >{settings.initMatcher}</span>   | это указывает на ID {settings.name}

			</Form.Text>
			<Form.Text id="deepLinkInputInfo" muted className="mb-20">
				Важно наличие в окончания ID <span className="highlight" >{settings.endMatcher}</span>   | это указывает на окончание ID {settings.name}
			</Form.Text>
			<Form.Text id="deepLinkInputInfo" className="mb-20" muted>
				ID {settings.name}:
			</Form.Text>
			<Form.Text id="deepLinkInputInfo" className="highlight mb-20">
				{getId(value, settings)}
			</Form.Text>
			<Form.Text id="deepLinkInputInfo" className="mb-20" muted>
				Результат:
			</Form.Text>
			<Form.Text id="deepLinkInputInfo" className="highlight mb-20">
				{makeDeepLinkFromUrl(value, settings)}
			</Form.Text>

			<Button disabled={!makeDeepLinkFromUrl(value, settings) || show} onClick={copy} variant={show ? "success" : "primary"}>
				{show ? "Скопировано" : "Копировать"}
			</Button>
		</>
	);
}

export default TextInput;
