const vk = {
	name: "VK",
	id: "vk",
	example: "https://vk.com/a4?z=video-81597813_456248778%2Fvideos-81597813%2Fpl_-81597813_-2",
	initMatcher: "?z=",
	endMatcher: "%",
	template: (id) => {
		return `apk_or_catalog://?deeplink_if_exist={"url":"vkvideo://video/${id}"}&packageName=com.vk.tv&systemName=system_com.vk.tv&projectId=d0205b93-82fe-4b88-9651-3799cd8a426c&catalog_system_name=catalog&server_action={"action_id":"RUN_APP_DEEPLINK","parameters":{"campaign_type":"deeplink","campaign_name":"video_automatic_download_open","action":{"type":"APPLICATION/SHOW"},"projectId":"d0205b93-82fe-4b88-9651-3799cd8a426c","request_type":"GET_APP_DESCRIPTION","automatic_download_open":true}}`
	}
}

const rutube = {
	name: "Rutube",
	id: "rutube",
	example: "https://rutube.ru/video/9819674007241e8e884bde58a5fde5c5/",
	initMatcher: "video/",
	endMatcher: "/",

	template: (id) => {
		return `apk_or_catalog://?deeplink_if_exist={"url":"rutube://rutube.ru/video/${id}"}&packageName=ru.rutube.app&systemName=system_ru.rutube.app&projectId=cb5525e2-94d6-4644-a61f-cbbabc3307af&catalog_system_name=catalog&server_action={"action_id":"RUN_APP_DEEPLINK","parameters":{"campaign_type":"deeplink","campaign_name":"video_automatic_download_open","action":{"type":"APPLICATION/SHOW"},"projectId":"cb5525e2-94d6-4644-a61f-cbbabc3307af","request_type":"GET_APP_DESCRIPTION","automatic_download_open":true}}`
	}
}

const getId = (url, settings) => {
	let deepLink = ""

	if (!url || url.length < 1) {
		return ""
	}

	let start = url.indexOf(settings.initMatcher)

	if (start === -1) {
		return ""
	}

	for (let i = start + settings.initMatcher.length; i < url.length; i++) {
		if (url[i] === settings.endMatcher) {
			break;
		}

		deepLink += url[i]
	}


	return deepLink
}

const makeDeepLinkFromUrl = (url, settings) => {
	let id = getId(url, settings)

	if (!id || id.length <= 1) {
		return ""
	}

	return settings.template(id)
}

const availableSettings = {
	"vk": vk,
	"rutube": rutube,
}

export {getId, makeDeepLinkFromUrl, availableSettings}
